<template>
  <div class="run-list" :class="{'run-list--full': isFull, 'run-list--visible': isVisible}">
    <run-list-header :toggleView="toggleView"/>
    <div class="run-list__wrap">
      <div class="run-list__main">
        <filters :allLength="Object.keys(getRunList).length" :watchListLength="Object.keys(getWatchList).length"
                 :activeList="activeList" :modifyActiveList="modifyActiveList"/>
        <div v-if="Object.keys(getItems).length > 0" :ref="'run_list__body'" class="run-list__body">
          <run-list-item v-for="(auctionItem, name, index) in getItems" :ref="auctionItem.InternalAuctionItemId"
                         :key="auctionItem.InternalAuctionItemId"
                         :auctionItem="auctionItem"
                         :toggleView="toggleView"
                         :index="index"/>
        </div>
      </div>
      <div class="run-list__info" v-if="isFull && lot">
        <div class="run-list__info-main">
          <div class="run-list__info-nav">
            <div class="run-list__info-nav-back" @click="toggleView(false)">Back to list</div>
            <div>
              {{ lot.index + 1 }} of {{ Object.keys(getItems).length }}
            </div>
            <div class="run-list__info-nav-arrows">
              <div class="run-list__info-nav-arrow" @click="prevLot(lot.SequenceNumber)"></div>
              <div class="run-list__info-nav-arrow" @click="nextLot(lot.SequenceNumber)"></div>
            </div>
          </div>
          <slider :images="lot.Images"/>
          <current-vehicle :lot="lot" isWatchList="true"/>
          <div class="run-list__autobid"
               v-if="(!getMaxAutoBid && !getIsBiddingStarting && getStateLot === 0) || isShowForm">
            <div class="run-list__autobid-form">
              <!--<div class="run-list__autobid-form-wrap">
                <div class="run-list__autobid-icon">
                <span class="icon icon-dollar">
                    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path
                        d="M27.482 41.571V48h-5.944v-6.411C15.208 40.737 11.223 37.02 11 31.512h6.779c.324 2.742 3.064 4.5 7.01 4.5 3.647 0 6.224-1.782 6.224-4.335 0-2.157-1.67-3.399-5.78-4.29l-4.366-.936c-6.108-1.29-9.1-4.5-9.1-9.657 0-5.49 3.786-9.348 9.771-10.317V0h5.944v6.468c5.813.951 9.736 4.752 9.918 9.951h-6.595c-.324-2.673-2.788-4.452-6.176-4.452-3.507 0-5.828 1.641-5.828 4.218 0 2.085 1.602 3.282 5.525 4.125l4.039.867C35.099 22.608 38 25.536 38 30.786c.003 5.946-3.938 9.876-10.518 10.785z"
                        fill-rule="evenodd"></path></svg>
                </span>
                </div>
                <label for="auto_bid_limit_lot"></label>
                <input type="number" v-model.number="autoBidValue" :min="autoBidValue" step="25"
                       name="auto_bid_limit_lot"
                       id="auto_bid_limit_lot"
                       class="form__control form__control--xs" placeholder="My Max Bid">
              </div>-->
              <div class="run-list__autobid-form-controls" v-if="!isLoading">
                <!--<button class="btn btn--main btn--md btn--block btn--flex" @click="placeAutoBid">Set
                  Autobid
                </button>-->
                <button class="btn btn--main btn--md btn--block btn--flex" v-if="getMaxAutoBid" @click="showForm">Cancel
                </button>
              </div>
              <loader v-else/>
            </div>
          </div>
          <div class="run-list__autobid-main run-list__autobid" v-else-if="getMaxAutoBid">
            <div class="run-list__autobid-value">
              <div>My Autobid</div>
              <div class="run-list__autobid-price">${{ getMaxAutoBid }}</div>
            </div>
            <template v-if="!getIsBiddingStarting">
              <div class="run-list__autobid-btn" @click="showForm">Change</div>
              <div class="run-list__autobid-btn" v-if="!getCurrentHighBidAmount && !getIsBiddingStarting"
                   @click="removeAutoBid">Remove
              </div>
            </template>
          </div>
          <div v-if="error" class="error">{{ error }}</div>
          <div class="run-list__info-tabs">
            <div class="run-list__info-tabs-name" :class="{'run-list__info-tabs-name--active': activeTab === 'details'}"
                 data-name="details" @click="changeActiveTab">Details
            </div>
            <div class="run-list__info-tabs-name" :class="{'run-list__info-tabs-name--active': activeTab === 'images'}"
                 data-name="images" @click="changeActiveTab">Images
            </div>
          </div>
          <vehicle v-if="activeTab === 'details'" :data="lot.Attributes"/>
          <img-container v-if="activeTab === 'images'" :images="lot.Images"/>
        </div>
        <img-container :images="lot.Images"/>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/AuctionContainer/RunList/Header";
import Item from "@/components/AuctionContainer/RunList/Item";
import slider from "@/components/AuctionContainer/Slider";
import filters from "@/components/AuctionContainer/RunList/Filters";
import vehicle from "@/components/AuctionContainer/Vehicle";
import ImgContainer from "@/components/AuctionContainer/ImgContainer";
import CurrentVehicle from "@/components/AuctionContainer/CurrentVehicle";
import loader from "@/components/Loader/Loader";
import {mapGetters, mapMutations} from 'vuex';
import {
  AUCTION_EVENT_AUTO_BID,
  AUCTION_EVENT_IAAI,
  AUCTION_EVENT_SCROLL_TO_CURRENT_LOT,
  AUCTION_EVENT_SHOW_POPUP, REMOVE_AUTO_BID
} from "@/consts";
import {SET_LOT} from "@/store/mutations.type";

export default {
  props: ['auctionId', 'toggleView', 'isFull', 'lot', 'isVisible'],
  name: "RunList",
  data: function () {
    return {
      activeList: 'all',
      activeTab: 'details',
      autoBidValue: 0,
      isLoading: false,
      error: '',
      isShowForm: false,
    }
  },
  components: {
    'run-list-header': Header,
    'run-list-item': Item,
    'img-container': ImgContainer,
    'current-vehicle': CurrentVehicle,
    filters,
    slider,
    vehicle,
    loader
  },
  methods: {
    ...mapMutations({setLot: SET_LOT}),
    /**
     * changes the visibility of the form
     *
     * @returns {void}
     */
    showForm() {
      this.isShowForm = !this.isShowForm;
    },
    /**
     * place auto bid
     *
     * @returns {void}
     */
    placeAutoBid() {
      let isAutoBid = false;

      if (this.getMaxAutoBid) {
        isAutoBid = true;
      }

      if (!this.autoBidValue) {
        this.error = 'Incorrect amount a bid!';
        return;
      }

      if (this.autoBidValue > this.getSpendingLimit) {
        this.error = 'The amount of the bet exceeds the spending limit! Spending limit equals $' + this.getSpendingLimit + '.';
        return;
      }

      if (this.autoBidValue % 25 !== 0) {
        this.error = 'The bid should be multiple 25!';
        return;
      }

      if (this.autoBidValue < this.getCurrentHighBidAmount) {
        this.error = 'The amount cannot be less than $' + this.getCurrentHighBidAmount;
        return;
      }

      this.error = '';
      this.isLoading = true;

      if (isAutoBid) {
        this.$iaaiService.iaaiClient.modifyAutoBid(this.lot.InternalAuctionId, this.lot.InternalAuctionItemId, this.autoBidValue, this.getUserId);
      } else {
        this.$iaaiService.iaaiClient.placeAutoBid(this.lot.InternalAuctionId, this.lot.InternalAuctionItemId, this.autoBidValue, this.getUserId);
      }
    },
    /**
     * event triggered AUCTION_EVENT_SHOW_POPUP
     *
     * @returns {void}
     */
    removeAutoBid() {
      this.$iaaiService.iaaiEventBus.$emit(AUCTION_EVENT_SHOW_POPUP, {
        title: 'Remove Autobid',
        data: {
          InternalAuctionId: this.lot.InternalAuctionId,
          InternalAuctionItemId: this.lot.InternalAuctionItemId,
          type: REMOVE_AUTO_BID,
        }
      })
    },
    /**
     * navigate to lot
     *
     * @param {Number} sequenceNumber - sequence number of current lot
     * @param {boolean} isNext - true = navigate to next lot, false = navigate to previous lot
     * @returns {void}
     */
    async navigateToLot(sequenceNumber, isNext = true) {
      const items = this.getRunListSequence;
      const itemsKeys = Object.keys(items);
      let sequence = null;

      let index = null;

      if (isNext) {
        index = this.lot.index + 1;
      } else {
        index = this.lot.index - 1;
      }

      if (index !== null) {

        if (itemsKeys[itemsKeys.length - 1] === sequenceNumber.toString() && isNext) {
          sequence = items[itemsKeys[0]];
          index = 0;
        } else if (-1 === index && !isNext) {
          sequence = items[itemsKeys[itemsKeys.length - 1]];
          index = itemsKeys.length - 1;
        } else {
          sequence = items[itemsKeys[index]];
        }

        const response = await this.$iaaiService.iaaiClient.auctionService.GetAuctionItem(this.auctionId, sequence, 'en', true);
        this.setLot({...response, index: index});
      }
    },
    /**
     * trades on the next lot
     *
     * @param {Number} sequenceNumber - sequence number of current lot
     * @returns {void}
     */
    nextLot(sequenceNumber) {
      this.navigateToLot(sequenceNumber);
    },
    /**
     * trades on the prev lot
     *
     * @param {Number} sequenceNumber - sequence number of current lot
     * @returns {void}
     */
    prevLot(sequenceNumber) {
      this.navigateToLot(sequenceNumber, false);
    },
    /**
     * scroll to current lot
     *
     * @param {Object} payload should contain InternalAuctionItemId
     */
    scrollToCurrentLot(payload) {
      this.$refs?.[payload.InternalAuctionItemId]?.[0]?.$el?.scrollIntoView({block: "center", behavior: "smooth"})
    },
    /**
     * modify the active lot list
     *
     * @param {String} name
     */
    modifyActiveList(name) {
      this.activeList = name;
    },
    /**
     * change the active tab
     * @param e
     */
    changeActiveTab(e) {
      const nameTab = e.target.dataset.name;

      if (nameTab) {
        this.activeTab = nameTab;
      }
    }
  },
  watch: {
    lot: function () {
      this.autoBidValue = 0;
      this.error = '';
    }
  },
  computed: {
    ...mapGetters(['runList', 'watchList', 'runListSequence', 'userId', 'maxAutoBid', 'spendingLimit', 'isBiddingStarting', 'status', 'currentHighBidAmount', 'currentBidLot', 'stateLot']),
    getCurrentBidLot() {
      return this.currentBidLot({InternalAuctionId: this.auctionId});
    },
    getRunList() {
      return this.runList(this.auctionId)
    },
    getWatchList() {
      return this.watchList({InternalAuctionId: this.auctionId});
    },
    getItems() {
      switch (this.activeList) {
        case "all":
          return this.getRunList
        case "watchlist":
          return this.getWatchList
        default:
          return {}
      }
    },
    getRunListSequence() {
      return this.runListSequence({InternalAuctionId: this.auctionId});
    },
    getUserId() {
      return this.userId
    },
    getMaxAutoBid() {
      return this.maxAutoBid({
        InternalAuctionId: this.lot.InternalAuctionId,
        InternalAuctionItemId: this.lot.InternalAuctionItemId
      })
    },
    getCurrentHighBidAmount() {
      return this.currentHighBidAmount({
        InternalAuctionId: this.lot.InternalAuctionId,
        InternalAuctionItemId: this.lot.InternalAuctionItemId
      })
    },
    getSpendingLimit() {
      return this.spendingLimit;
    },
    getIsBiddingStarting() {
      return this.isBiddingStarting({
        InternalAuctionId: this.lot.InternalAuctionId,
        InternalAuctionItemId: this.lot.InternalAuctionItemId
      });
    },
    getStatus() {
      return this.status(this.lot.InternalAuctionId)
    },
    getStateLot() {
      return this.stateLot({
        InternalAuctionId: this.lot.InternalAuctionId,
        InternalAuctionItemId: this.lot.InternalAuctionItemId
      });
    }
  },
  created() {
    /**
     * AUCTION_EVENT_SCROLL_TO_CURRENT_LOT event subscription
     */
    this.$iaaiService.iaaiEventBus.$on(AUCTION_EVENT_SCROLL_TO_CURRENT_LOT, this.scrollToCurrentLot);
    const self = this;
    /**
     * event subscription
     */
    this.$iaaiService.iaaiEventBus.$on(AUCTION_EVENT_IAAI + self.auctionId, function (payload) {
      if (payload.iaaiEvent === AUCTION_EVENT_AUTO_BID) {
        self.isLoading = false;
        self.isShowForm = false;
      }
    });
  }
}
</script>

<style lang="scss">
.run-list {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  word-break: break-word;
  height: 100%;

  @media screen and (min-width: 1250px) {
    min-width: 50rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
    width: 100%;

    &--visible {
      display: flex;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1249px) {
    width: 50%;
  }

  &__autobid {
    margin-bottom: 1rem;
    padding: 1rem;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: #F2F2F2;

    &-main {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &-value {
      text-align: center;
      color: #666;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 700;
    }

    &-price {
      font-size: 2rem;
      font-weight: 400;
      color: #000;
      letter-spacing: .1rem;
    }

    &-btn {
      text-transform: uppercase;
      font-size: .9rem;
      color: #1475c7;
      font-weight: 600;
      cursor: pointer;
      padding: .5rem;
      transition: all .2s ease-in-out;

      &:hover {
        color: #008aff;
        transform: scale(1.2);
      }
    }

    &-icon {
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        width: 1.5rem;
        line-height: 1;
      }
    }

    &-form {
      border-radius: .4rem;
      overflow: hidden;

      &-controls {
        display: flex;
        padding-top: 10px;

        @media screen and (max-width: 1460px) {
          width: 100%;

          .btn {
            font-size: 1.2rem;
          }
        }

        .btn {
          white-space: nowrap;
          flex: 1;

          & + .btn {
            margin-left: 10px;
          }
        }
      }

      &-wrap {
        display: flex;
        background-image: linear-gradient(0deg, #f8f8f8, #fff);
        border: 1px solid #ccc;
        border-radius: .4rem;
        overflow: hidden;
        flex: 1;
        min-width: 110px;
      }

      .form__control {
        padding: 1rem 1rem .9rem;
        border-radius: 0;
        border: 0;
        border-left: 1px solid #ccc;
      }

      .loader {
        padding-top: 10px;
      }
    }
  }

  &--full {
    width: 66.666%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }


    @media screen and (min-width: 768px) and (max-width: 1249px) {
      width: 50%;
    }

    @media screen and (max-width: 1249px) {

      .run-list {

        &__main {
          display: none;
        }

        &__info {
          width: 100%;

          & > .img-container {
            display: none;
          }
        }
      }
    }
  }

  &__body {
    overflow-y: auto;
    flex: 1;
    background: #fff;
  }

  &__wrap {
    display: flex;
    flex: 1;
    background: #fff;
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (min-width: 768px) and (max-width: 1249px) {
      border: 1px solid #ccc;
    }
  }

  &__info {
    border-left: 1px solid #ccc;
    display: flex;
    width: 66.666%;
    overflow: hidden;

    .slider {

      &__img {
        max-width: 21rem;
      }
    }

    &-nav {
      color: #666;
      font-weight: 600;
      padding: .5rem 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px solid #ccc;

      &-back {
        color: #1679e0;
        margin-right: auto;
        display: flex;
        align-items: center;

        &:before {
          content: '';
          width: 2rem;
          height: 2rem;
          background-image: url("/assets/img/icons/left-arrow.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          margin-right: .5rem;
        }

        @media screen and (min-width: 1250px) {
          display: none;
        }
      }

      &-arrows {
        display: flex;
        border: 1px solid #ccc;
        margin-left: .5rem;
        border-radius: .5rem;
      }

      &-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.3rem;
        cursor: pointer;

        &:hover {

          &:before {
            background: #000;
          }
        }

        &:before {
          content: '';
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          padding: .5rem;
          background: #666;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all .2s ease-in-out;
        }

        &:first-child {
          border-right: 1px solid #ccc;

          &:before {
            transform: translate(-50%, -50%) rotate(-90deg);
          }
        }

        &:last-child {

          &:before {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
    }

    &-main {
      border: 1px solid #ccc;
      border-top: 0;
      border-bottom: 0;
      overflow-y: auto;

      .vehicle {
        overflow: hidden;
        height: unset;


      }

      .current-vehicle {

        &__stock-number {
          margin-right: auto;
        }
      }
    }

    > div {
      flex: 1;
    }

    &-tabs {
      display: flex;

      &-name {
        flex: 1;
        border-bottom: .2rem solid rgba(0, 0, 0, .5);
        text-align: center;
        font-weight: 700;
        padding: .5rem;
        $name: &;

        &:not(#{$name}--active) {
          opacity: .5;
          cursor: pointer;
        }

        &--active {
          border-color: #b10000;
          pointer-events: none;
          border-width: .3rem;
        }
      }
    }
  }
}
</style>
